import React, { useContext, useEffect, useState } from 'react';
import {
  Tooltip,
  Avatar,
  Dropdown,
  Menu,
  Button,
  Modal,
} from '@arco-design/web-react';
import AvatarImageSrc from '@/assets/avatar.jpg';
import {
  IconSunFill,
  IconMoonFill,
  IconUser,
  IconSettings,
  IconPoweroff,
  IconLoading,
  IconEdit,
} from '@arco-design/web-react/icon';
import { useSelector } from 'react-redux';
import { GlobalState } from '@/store';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import Logo from '@/assets/logo.svg';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import useStorage from '@/utils/useStorage';
import UpdateUserInfo from './updateUserInfo';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const { userInfo, userLoading } = useSelector((state: GlobalState) => state);

  const [_, setToken] = useStorage('token');

  const [updateUserInfoVisible, setUpdateUserInfoVisible] = useState(false);

  const { theme, setTheme } = useContext(GlobalContext);

  function logout() {
    setToken('');
    window.location.href = '/login';
  }

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    }
    if (key === 'updatePassword') {
      setUpdateUserInfoVisible(true);
    }
  }

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="updatePassword">
        <IconEdit className={styles['dropdown-icon']} />
        修改密码
      </Menu.Item>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        退出登录
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo />
          <div className={styles['logo-name']}>微蚁儿</div>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        {/* <Settings /> */}
        {userInfo && (
          <li>
            <Dropdown droplist={droplist} position="br" disabled={userLoading}>
              <Avatar size={32} style={{ cursor: 'pointer' }}>
                {userLoading ? <IconLoading /> : <img src={AvatarImageSrc} />}
              </Avatar>
            </Dropdown>
          </li>
        )}
      </ul>
      <Modal
        title={'修改密码'}
        visible={updateUserInfoVisible}
        footer={null}
        style={{ width: 800 }}
        unmountOnExit
        maskClosable={false}
        focusLock={false}
        onCancel={() => {
          setUpdateUserInfoVisible(false);
        }}
      >
        <UpdateUserInfo
          onClose={() => {
            setUpdateUserInfoVisible(false);
          }}
          onSuccess={() => {
            setUpdateUserInfoVisible(false);
            logout();
          }}
        />
      </Modal>
    </div>
  );
}

export default Navbar;
