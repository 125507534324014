import auth, { AuthParams } from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';

export type IRoute = AuthParams & {
  name: string;
  key: string;
  // 当前页是否展示面包屑
  breadcrumb?: boolean;
  children?: IRoute[];
  // 当前路由是否渲染菜单项，为 true 的话不会在菜单中显示，但可通过路由地址访问。
  ignore?: boolean;
};

export const routes: IRoute[] = [
  {
    name: '欢迎页',
    key: 'welcome',
    requiredPermissions: [],
  },
  {
    name: '会员管理',
    key: 'member',
    children: [
      {
        name: '会员列表',
        key: 'member/list',
      },
      {
        name: '等级变更记录',
        key: 'member/levelRecord',
      },
      {
        name: '会员等级',
        key: 'member/level',
      },
      {
        name: '等级卡券',
        key: 'member/levelCoupon',
      },
      {
        name: '邀请管理',
        key: 'member/invite',
      },
    ],
  },
  {
    name: '商品管理',
    key: 'goods',
    children: [
      {
        name: '商品列表',
        key: 'goods/list',
      },
      {
        name: '商品编辑',
        key: 'goods/update',
        ignore: true,
      },
      {
        name: '订单管理',
        key: 'goods/order',
      },
    ],
  },
  {
    name: '返利订单',
    key: 'commissionOrder',
    children: [
      {
        name: '订单列表',
        key: 'commissionOrder/list',
      },
    ],
  },
  {
    name: 'CPS订单',
    key: 'vOrder',
    children: [
      {
        name: '订单列表',
        key: 'vOrder/list',
      },
      {
        name: '佣金卡券',
        key: 'vOrder/commissionCoupon',
      },
      {
        name: '推荐奖励',
        key: 'vOrder/commissionReward',
      },
      {
        name: '红包奖励',
        key: 'vOrder/redpackReward',
      },
      {
        name: '价保监控',
        key: 'vOrder/protection',
      },
      {
        name: '广告数据',
        key: 'vOrder/adPromotionData',
      },
    ],
  },
  {
    name: '财务管理',
    key: 'finance',
    children: [
      {
        name: '会员钱包',
        key: 'finance/wallet',
      },
      {
        name: '钱包流水',
        key: 'finance/walletRecord',
      },
      {
        name: '提现审核',
        key: 'finance/withdraw',
      },
      {
        name: '红包流水',
        key: 'finance/redpack',
      },
    ],
  },
  {
    name: '微信管理',
    key: 'wechat',
    children: [
      {
        name: '微信消息',
        key: 'wechat/message',
      },
      {
        name: '回复配置',
        key: 'wechat/messageConfig',
      },
      {
        name: '菜单管理',
        key: 'wechat/menu',
      },
    ],
  },
  {
    name: '首页配置',
    key: 'indexConfig',
    children: [
      {
        name: '配置管理',
        key: 'indexConfig/index',
      },
      {
        name: '专题活动',
        key: 'indexConfig/special',
      },
    ],
  },
  // {
  //   name: '系统设置',
  //   key: 'setting',
  //   children: [
  //     {
  //       name: '活动管理',
  //       key: 'setting/activity',
  //     },
  //   ],
  // },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};

export const generatePermission = (role: string) => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach((item) => {
    if (item.children) {
      item.children.forEach((child) => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};

const useRoute = (userPermission): [IRoute[], string] => {
  const filterRoute = (routes: IRoute[], arr = []): IRoute[] => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const { requiredPermissions, oneOfPerm } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({ requiredPermissions, oneOfPerm }, userPermission);
      }

      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = { ...route, children: [] };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({ ...route });
      }
    }

    return arr;
  };

  const [permissionRoute, setPermissionRoute] = useState(routes);

  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);

  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);

  return [permissionRoute, defaultRoute];
};

export default useRoute;
