/* eslint-disable */
import request from './request';
/**
 * 登录
 *
 * @returns
 */
export async function login(data: any) {
  return request('/login', {
    data,
    method: 'post',
  });
}
/**
 * 修改密码
 *
 * @returns
 */
export async function mineUpdatePassword(data: any) {
  return request('/mine/update/password', {
    data,
    method: 'post',
  });
}
/**
 * 获取个人信息
 *
 * @returns
 */
export async function mineUserInfo(data: any) {
  return request('/mine/userInfo', {
    data,
    method: 'get',
  });
}

/**
 * 获取会员等级变更历史
 *
 * @param data
 * @returns
 */
export async function memberLevelRecordPaginate(data: any) {
  return request('/memberLevel/record/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 设置会员等级
 *
 * @param data
 * @returns
 */
export async function memberLevelSet(data: any) {
  return request('/memberLevel/set', {
    data,
    method: 'post',
  });
}

/**
 * 获取订单列表
 *
 * @returns
 */
export async function orderPaginate(data: any) {
  return request('/order/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 获取订单详情
 *
 * @returns
 */
export async function orderDetail(data: any) {
  return request('/order/detail', {
    data,
    method: 'get',
  });
}
/**
 * 获取订单字段字典
 *
 * @returns
 */
export async function orderFieldDict(data: any) {
  return request('/order/fieldDict', {
    data,
    method: 'get',
  });
}

/**
 * 订单修改关联会员
 *
 * @param data
 * @returns
 */
export async function orderBindMember(data: any) {
  return request('/order/bindMember', {
    data,
    method: 'post',
  });
}

/**
 * 结算订单
 *
 * @param data
 * @returns
 */
export async function orderSettle(data: any) {
  return request('/order/settle', {
    data,
    method: 'post',
  });
}

/**
 * 刷新订单
 *
 * @param data
 * @returns
 */
export async function orderRefresh(data: any) {
  return request('/order/refresh', {
    data,
    method: 'post',
  });
}

/**
 * 分析订单
 *
 * @param data
 * @returns
 */
export async function orderAnalyze(data: any) {
  return request('/order/analyze', {
    data,
    method: 'post',
  });
}

/**
 * 统计订单数据
 *
 * @param data
 * @returns
 */
export async function orderCommissionCharts(data: any) {
  return request('/order/charts/commission', {
    data,
    method: 'get',
  });
}
/**
 * 订单统计
 *
 * @returns
 */
export async function orderStatistics(data: any) {
  return request('/order/statistics', {
    data,
    method: 'get',
  });
}

/**
 * 获取会员列表
 *
 * @returns
 */
export async function memberPaginate(data: any) {
  return request('/member/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取会员详情
 *
 * @returns
 */
export async function memberDetail(data: any) {
  return request('/member/detail', {
    data,
    method: 'get',
  });
}
/**
 * 加入内测
 *
 * @returns
 */
export async function memberJoinBeta(data: any) {
  return request('/member/joinBeta', {
    data,
    method: 'post',
  });
}

/**
 * 退出内测
 *
 * @returns
 */
export async function memberQuitBeta(data: any) {
  return request('/member/quitBeta', {
    data,
    method: 'post',
  });
}
/**
 * 会员统计
 *
 * @returns
 */
export async function memberStatistics(data: any) {
  return request('/member/statistics', {
    data,
    method: 'get',
  });
}
/**
 * 全量统计
 *
 * @returns
 */
export async function statisticsSummary(data: any) {
  return request('/charts/statistic', {
    data,
    method: 'get',
  });
}

/**
 * 获取微信消息回复配置列表
 *
 * @returns
 */
export async function wechatMessageReplyPaginate(data: any) {
  return request('/wechatMessageReply/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 修改或新增微信消息回复配置
 *
 * @returns
 */
export async function wechatMessageReplyUpdateOrCreate(data: any) {
  return request('/wechatMessageReply/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除微信消息回复配置
 *
 * @returns
 */
export async function wechatMessageReplyDelete(data: any) {
  return request('/wechatMessageReply/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取佣金卡券的列表
 *
 * @returns
 */
export async function commissionCouponList(data: any) {
  return request('/commissionCoupon/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取佣金卡券的发放记录
 *
 * @returns
 */
export async function commissionCouponAccountPaginate(data: any) {
  return request('/commissionCouponAccount/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 发放佣金卡券
 *
 * @returns
 */
export async function commissionCouponSend(data: any) {
  return request('/commissionCouponAccount/send', {
    data,
    method: 'post',
  });
}

/**
 * 将佣金卡券置为失效
 *
 * @returns
 */
export async function commissionCouponInvalid(data: any) {
  return request('/commissionCouponAccount/invalid', {
    data,
    method: 'post',
  });
}

/**
 * 将佣金卡券重新激活
 *
 * @returns
 */
export async function commissionCouponActive(data: any) {
  return request('/commissionCouponAccount/active', {
    data,
    method: 'post',
  });
}

/**
 * 获取会员等级卡券的发放记录
 *
 * @returns
 */
export async function memberLevelCardPaginate(data: any) {
  return request('/memberLevelCard/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 发放会员等级卡券
 *
 * @returns
 */
export async function memberLevelCardSend(data: any) {
  return request('/memberLevelCard/send', {
    data,
    method: 'post',
  });
}

/**
 * 将会员等级卡券置为失效
 *
 * @returns
 */
export async function memberLevelCardInvalid(data: any) {
  return request('/memberLevelCard/invalid', {
    data,
    method: 'post',
  });
}

/**
 * 将会员等级卡券重新激活
 *
 * @returns
 */
export async function memberLevelCardActive(data: any) {
  return request('/memberLevelCard/active', {
    data,
    method: 'post',
  });
}

/**
 * 获取所有上级列表
 *
 * @returns
 */
export async function memberRefereeListReferee(data: any) {
  return request('/memberReferee/referee/list', {
    data,
    method: 'get',
  });
}

/**
 * 根据ID获取下级列表
 *
 * @returns
 */
export async function memberRefereeList(data: any) {
  return request('/memberReferee/list', {
    data,
    method: 'get',
  });
}

/**
 * 获取预估的订单奖励
 *
 * @returns
 */
export async function memberRefereeOrderRewardPub(data: any) {
  return request('/memberReferee/orderReward/pub', {
    data,
    method: 'get',
  });
}

/**
 * 分页获取所有的上下级关系列表
 *
 * @returns
 */
export async function memberRefereePaginate(data: any) {
  return request('/memberReferee/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取所有等级列表
 *
 * @returns
 */
export async function memberLevelList(data: any) {
  return request('/memberLevel/list', {
    data,
    method: 'get',
  });
}

/**
 * 修改等级的比例配置
 *
 * @returns
 */
export async function memberLevelUpdateRateConfig(data: any) {
  return request('/memberLevel/update/rateConfig', {
    data,
    method: 'post',
  });
}

/**
 * 修改等级的邀请规则
 *
 * @returns
 */
export async function memberLevelUpdateInviteRule(data: any) {
  return request('/memberLevel/update/inviteRuleConfig', {
    data,
    method: 'post',
  });
}

/**
 * 修改等级的提现规则
 *
 * @returns
 */
export async function memberLevelUpdateWithdrawRule(data: any) {
  return request('/memberLevel/update/withdrawRuleConfig', {
    data,
    method: 'post',
  });
}

/**
 * 修改等级的升级规则
 *
 * @returns
 */
export async function memberLevelUpdateUpgradeRule(data: any) {
  return request('/memberLevel/update/upgradeRuleConfig', {
    data,
    method: 'post',
  });
}

/**
 * 获取提现列表
 *
 * @returns
 */
export async function withdrawPaginate(data: any) {
  return request('/withdraw/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 同意提现
 *
 * @returns
 */
export async function withdrawAccept(data: any) {
  return request('/withdraw/accept', {
    data,
    method: 'post',
  });
}

/**
 * 驳回提现
 *
 * @returns
 */
export async function withdrawRefuse(data: any) {
  return request('/withdraw/refuse', {
    data,
    method: 'post',
  });
}

/**
 * 获取佣金奖励（订单奖励）列表
 *
 * @returns
 */
export async function orderRewardPaginate(data: any) {
  return request('/reward/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 生成佣金奖励（订单奖励）上月账单
 *
 * @returns
 */
export async function orderRewardGenerateBill(data: any) {
  return request('/reward/generateBill', {
    data,
    method: 'post',
  });
}

/**
 * 发放佣金奖励（订单奖励）
 *
 * @returns
 */
export async function orderRewardSendReward(data: any) {
  return request('/reward/send', {
    data,
    method: 'post',
  });
}

/**
 * 获取佣金奖励（红包奖励）列表
 *
 * @returns
 */
export async function orderRewardRedpackPaginate(data: any) {
  return request('/rewardRedpack/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 生成佣金奖励（红包奖励）上月账单
 *
 * @returns
 */
export async function orderRewardRedpackGenerateBill(data: any) {
  return request('/rewardRedpack/generateBill', {
    data,
    method: 'post',
  });
}

/**
 * 发放佣金奖励（红包奖励）
 *
 * @returns
 */
export async function orderRewardRedpackSendReward(data: any) {
  return request('/rewardRedpack/send', {
    data,
    method: 'post',
  });
}
/**
 * 发送佣金奖励（红包奖励）领取提醒
 *
 * @returns
 */
export async function orderRewardRedpackSendRewardMessage(data: any) {
  return request('/rewardRedpack/sendMessage/multi', {
    data,
    method: 'post',
  });
}

/**
 * 获取商品列表
 *
 * @returns
 */
export async function shopGoodsPaginate(data: any) {
  return request('/shopGoods/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取商品详情
 *
 * @returns
 */
export async function shopGoodsDetail(data: any) {
  return request('/shopGoods/detail', {
    data,
    method: 'get',
  });
}

/**
 * 创建或修改商品
 *
 * @returns
 */
export async function shopGoodsUpdateOrCreate(data: any) {
  return request('/shopGoods/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除商品
 *
 * @returns
 */
export async function shopGoodsDelete(data: any) {
  return request('/shopGoods/delete', {
    data,
    method: 'post',
  });
}

/**
 * 上架商品
 *
 * @returns
 */
export async function shopGoodsEnable(data: any) {
  return request('/shopGoods/enable', {
    data,
    method: 'post',
  });
}

/**
 * 下架商品
 *
 * @returns
 */
export async function shopGoodsDisable(data: any) {
  return request('/shopGoods/disable', {
    data,
    method: 'post',
  });
}

/**
 * 获取商品订单列表
 *
 * @returns
 */
export async function shopGoodsOrderPaginate(data: any) {
  return request('/shopGoods/order/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 商品订单赠送
 *
 * @returns
 */
export async function shopGoodsOrderGive(data: any) {
  return request('/shopGoods/order/give', {
    data,
    method: 'post',
  });
}

/**
 * 获取商品监控任务列表
 *
 * @returns
 */
export async function goodsMonitorTaskPaginate(data: any) {
  return request('/goodsMonitorTask/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取商品监控任务详情
 *
 * @returns
 */
export async function goodsMonitorTaskDetail(data: any) {
  return request('/goodsMonitorTask/detail', {
    data,
    method: 'get',
  });
}

/**
 * 停止商品监控任务
 *
 * @returns
 */
export async function goodsMonitorTaskStop(data: any) {
  return request('/goodsMonitorTask/stop', {
    data,
    method: 'post',
  });
}

/**
 * 获取微信消息历史记录
 *
 * @returns
 */
export async function wechatMessageHistoryPaginate(data: any) {
  return request('/wechatMessageHistory/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取钱包流水记录
 *
 * @returns
 */
export async function walletRecordPaginate(data: any) {
  return request('/walletRecord/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 获取钱包列表
 *
 * @returns
 */
export async function walletPaginate(data: any) {
  return request('/wallet/paginate', {
    data,
    method: 'get',
  });
}
/**
 * 钱包充值
 *
 * @returns
 */
export async function walletRecharge(data: any) {
  return request('/wallet/recharge', {
    data,
    method: 'post',
  });
}

/**
 * 获取订单列表
 *
 * @param data
 * @returns
 */
export async function commissionOrderPaginate(data: any) {
  return request('/commissionOrder/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取首页配置列表
 *
 * @param data
 * @returns
 */
export async function listIndexConfig(data: any) {
  return request('/index/config/list', {
    data,
    method: 'get',
  });
}

/**
 * 编辑首页配置
 *
 * @param data
 * @returns
 */
export async function updateOrCreateIndexConfig(data: any) {
  return request('/index/config/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除首页配置
 *
 * @param data
 * @returns
 */
export async function deleteIndexConfig(data: any) {
  return request('/index/config/delete', {
    data,
    method: 'post',
  });
}

/**
 * 获取首页配置活动列表
 *
 * @param data
 * @returns
 */
export async function listIndexConfigActivity(data: any) {
  return request('/index/configActivity/list', {
    data,
    method: 'get',
  });
}

/**
 * 编辑首页配置活动
 *
 * @param data
 * @returns
 */
export async function updateOrCreateIndexConfigActivity(data: any) {
  return request('/index/configActivity/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除首页配置活动
 *
 * @param data
 * @returns
 */
export async function deleteIndexConfigActivity(data: any) {
  return request('/index/configActivity/delete', {
    data,
    method: 'post',
  });
}

/**
 * 检测首页配置活动
 *
 * @param data
 * @returns
 */
export async function pingIndexConfigActivity(data: any) {
  return request('/index/configActivity/ping', {
    data,
    method: 'post',
  });
}

/**
 * 开启实时结算
 *
 * @param data
 * @returns
 */
export async function enableRealTimeSettle(data: any) {
  return request('/member/realTimeSettle/enable', {
    data,
    method: 'post',
  });
}

/**
 * 关闭实时结算
 *
 * @param data
 * @returns
 */
export async function disableRealTimeSettle(data: any) {
  return request('/member/realTimeSettle/disable', {
    data,
    method: 'post',
  });
}

/**
 * 发送验证码
 *
 * @param data
 * @returns
 */
export async function sendCaptcha(data: any) {
  return request('/member/sendVerifyCode', {
    data,
    method: 'post',
  });
}

/**
 * 设置用户的微信广告推广位ID
 *
 * @param data
 * @returns
 */
export async function updateMemberWechatAdzoneId(data: any) {
  return request('/member/setWechatAdzoneId', {
    data,
    method: 'post',
  });
}

/**
 * 获取红包流水
 *
 * @param data
 * @returns
 */
export async function paginateRedpack(data: any) {
  return request('/redpackHistory/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 发送红包
 *
 * @param data
 * @returns
 */
export async function sendRedpack(data: any) {
  return request('/redpackHistory/send', {
    data,
    method: 'post',
  });
}

/**
 * 创建红包并发送
 *
 * @param data
 * @returns
 */
export async function createRedpack(data: any) {
  return request('/redpackHistory/createAndSend', {
    data,
    method: 'post',
  });
}

/**
 * 获取微信菜单配置
 *
 * @param data
 * @returns
 */
export async function getWechatConfigForMenu(data: any) {
  return request('/wechatConfig/menu/get', {
    data,
    method: 'get',
  });
}

/**
 * 设置微信菜单配置
 *
 * @param data
 * @returns
 */
export async function setWechatConfigForMenu(data: any) {
  return request('/wechatConfig/menu/set', {
    data,
    method: 'post',
  });
}

/**
 * 为会员添加标签
 *
 * @param data
 * @returns
 */
export async function addUserTagForWechat(data: any) {
  return request('/member/addUserTagForWechat', {
    data,
    method: 'post',
  });
}

/**
 * 获取广告位数据
 *
 * @param data
 * @returns
 */
export async function adPromotionDataPaginate(data: any) {
  return request('/adSitePromotionData/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 同步昨日数据
 *
 * @param data
 * @returns
 */
export async function adPromotionDataRunner(data: any) {
  return request('/adSitePromotionData/runner', {
    data,
    method: 'post',
  });
}

/**
 * 结算
 *
 * @param data
 * @returns
 */
export async function adPromotionDataSettle(data: any) {
  return request('/adSitePromotionData/settle', {
    data,
    method: 'post',
  });
}

/**
 * 专题活动列表
 *
 * @param data
 * @returns
 */
export async function indexActivitySpecialList(data: any) {
  return request('/index/activitySpecial/list', {
    data,
    method: 'get',
  });
}

/**
 * 编辑
 *
 * @param data
 * @returns
 */
export async function indexActivitySpecialUpdateOrCreate(data: any) {
  return request('/index/activitySpecial/updateOrCreate', {
    data,
    method: 'post',
  });
}

/**
 * 删除
 *
 * @param data
 * @returns
 */
export async function indexActivitySpecialDelete(data: any) {
  return request('/index/activitySpecial/delete', {
    data,
    method: 'post',
  });
}
